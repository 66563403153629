import { useMemo, useState } from "react";
import clsx from "clsx";
import Button from "components/Button";
import ChallengeCardHorizontal from "components/ChallengeCardHorizontal";
import { useRouter } from "next/router";
import { logger } from "../../utils";
import { CType } from "../../models/Contentful/types";
import styles from "./challengeListBlade.module.sass";

interface IButtonTextMap {
  [key: string]: string;
}

const Tab = {
  PAST: "past",
  CURRENT: "current",
  FUTURE: "future",
};

const ButtonTextMap: IButtonTextMap = {
  [Tab.FUTURE]: "Upcoming Challenges",
  [Tab.CURRENT]: "Current Challenges",
  [Tab.PAST]: "Past Challenges",
};

const ChallengeListBlade: React.FC<CType<any>> = ({ fields }) => {
  const router = useRouter();
  const filter: string = (router?.query?.tab ?? "") as string;

  const [currentTab, setCurrentTab] = useState<string>(filter || Tab.CURRENT);
  const {
    past,
    current,
    future,
    pastDescription,
    currentDescription,
    futureDescription,
  } = fields;

  const [currentContent, description] = useMemo(() => {
    if (currentTab === Tab.CURRENT && current?.length > 0) {
      return [current, currentDescription];
    }
    if (currentTab === Tab.PAST && past?.length > 0) {
      return [past, pastDescription];
    }
    if (currentTab === Tab.FUTURE && future?.length > 0) {
      return [future, futureDescription];
    }
    return [[], undefined];
  }, [
    current,
    currentTab,
    future,
    past,
    currentDescription,
    pastDescription,
    futureDescription,
  ]);

  try {
    if (
      (past?.length || 0) + (current?.length || 0) + (future?.length || 0) ===
      0
    ) {
      return null;
    }

    const handleClick = (tab: string) => {
      setCurrentTab(tab);
      router.push(
        {
          pathname: `/challenges`,
          query: { tab },
        },
        undefined,
        {
          shallow: true,
        },
      );
    };

    return (
      <div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonContainerInner}>
            {[Tab.FUTURE, Tab.CURRENT, Tab.PAST].map((item) => (
              <Button
                onlyText
                underline
                onClick={() => handleClick(item)}
                classes={clsx(
                  item === currentTab && styles.tabSelected,
                  (fields[item]?.length || 0) === 0 && styles.buttonDisabled,
                )}
              >
                {ButtonTextMap[item]}
              </Button>
            ))}
          </div>
          {description && (
            <div className={styles.contentDescription}>
              {description.content[0].content[0].value}
            </div>
          )}
        </div>
        <div className="is-bgcolor-Blue">
          <div className={clsx("container", styles.cardsContainer)}>
            {currentContent.map((item: any) => (
              <ChallengeCardHorizontal {...item} />
            ))}
          </div>
        </div>
      </div>
    );
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default ChallengeListBlade;
